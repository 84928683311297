import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Asset } from "@models/asset/asset.model";
import { AssetService } from "./asset.service";
import {
  ArchiveRequestStatus,
  SearchType,
  Sort,
  StatesLibrary,
  Status,
} from "@models/shared/searchType";
import { Observable, Subject } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  debounceTime,
  switchMap,
  distinctUntilChanged,
  tap,
  filter,
  first,
  map,
} from "rxjs/operators";
import { CatalogAssetSelectionService } from "./../shared/catalog-assets-selection.service";
import { ToolsService } from "~/app/shared/tools.service";
import { MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Selection } from "~/app/shared/assets-selection.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogConfirmCancelDeleteComponent } from "../shared/components/dialog-confirm-cancel-delete/dialog-confirm-cancel-delete.component";

@Component({
  selector: "app-asset",
  templateUrl: "./asset.component.html",
  styleUrls: ["./asset.component.scss"],
})
export class AssetComponent implements OnInit {
  public selectedAssets: Asset[] = [];
  public simpleBack = false;
  public originalAssetSelection: Selection;
  public isAssetSelection = false;
  public backLabel: string;
  private assetSelectionSnackBar: MatSnackBarRef<SimpleSnackBar>;
  assets: Asset[];
  public searchTypes = Object.values(SearchType);
  public showClearSearchIcon = false;
  public searchStringEdited = new Subject<string>();
  public resultsByPageList = [25, 50, 100, 200];
  public searchFormGroup: FormGroup;
  public pageFormGroup: FormGroup;
  public responseResults: number = 0;
  public thumbnailsStates = ["show", "hide"];
  public sorts = [
    {
      label: "alphabetical",
      value: Sort.Alphabetical,
      parents: [SearchType.Similar, SearchType.Contains, SearchType.BeginsWith],
    },
    {
      label: "createdDate",
      value: Sort.CreatedDate,
      parents: [SearchType.Similar, SearchType.Contains, SearchType.BeginsWith],
    },
    {
      label: "createdDateDesc",
      value: Sort.CreatedDateDesc,
      parents: [SearchType.Similar, SearchType.Contains, SearchType.BeginsWith],
    },
  ];
  public searchSettings = {
    thumbnailsVisible: true,
    resultsPerPage: this.resultsByPageList[0],
    sortingOrder: this.sorts[2].value,
  };

  public statusValues = [
    StatesLibrary.All,
    StatesLibrary.Deleted,
    StatesLibrary.Failed,
    StatesLibrary.Processing,
    StatesLibrary.Archived,
  ].map((v: any) => ({ label: v, value: v }));

  public frameRates: string[] = [];
  public fileTypes: string[] = [];

  public from = 0;

  @ViewChild("keywordInput")
  protected keywordInputElement: ElementRef;
  @ViewChild("titleResults")
  protected titleResults: ElementRef;

  constructor(
    protected formBuilder: FormBuilder,
    private assetService: AssetService,
    protected assetSelectionService: CatalogAssetSelectionService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected toolsService: ToolsService
  ) {}

  async ngOnInit() {
    this.extractBackLabel();
    await this.initSearchFormGroup();
    this.searchFormGroup.updateValueAndValidity();
  }

  async initSearchFormGroup(): Promise<any> {
    this.pageFormGroup = this.formBuilder.group({
      searchString: "",
      search: this.formBuilder.group({
        keyword: "",
        frameRate: "",
        assetStatus: "",
        fileType: "",
        createdFrom: "",
        createdTo: "",
        searchType: SearchType.Similar,
        sortResults: this.searchSettings.sortingOrder,
        resultsPerPage: this.searchSettings.resultsPerPage,
      }),
    });
    this.searchFormGroup = this.pageFormGroup.get("search") as FormGroup;

    this.searchFormGroup.valueChanges
      .pipe(
        distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
        filter((_) => this.searchFormGroup.valid),
        tap(() => {
          this.updateClearSearchVisibility();
        }),
        debounceTime(300),
        filter((_) => this.searchFormGroup.valid),
        switchMap(() => this.searchAssets())
      )
      .subscribe((response) => {
        this.manageSearchResponse(response);
      });

    this.searchStringEdited.subscribe((changedString) => {
      this.searchFormGroup.get("keyword").setValue(changedString.trim());
    });
  }

  public searchAssets(): Observable<any> {
    const filters = {
      keyword: this.searchFormGroup.get("keyword").value,
      frameRate: this.searchFormGroup.get("frameRate").value,
      assetStatus: this.searchFormGroup.get("assetStatus").value,
      fileType: this.searchFormGroup.get("fileType").value,
      createdFrom: this.searchFormGroup.get("createdFrom").value,
      createdTo: this.searchFormGroup.get("createdTo").value,
      searchType: SearchType.Contains,
      sortResults: this.searchFormGroup.get("sortResults").value,
      resultsPerPage: this.searchFormGroup.get("resultsPerPage").value,
      from: Math.floor(
        this.from / this.searchFormGroup.get("resultsPerPage").value
      ),
    };
    return this.assetService.getAll(filters);
  }

  public manageSearchResponse(response: any): void {
    this.assets = response.assets;
    this.frameRates.push("All");
    this.frameRates = [
      ...this.frameRates,
      ...response.frameRates
        .map((v: any) => v.frameRate)
        .filter((item: any) => item !== null && item !== undefined),
    ];
    this.fileTypes.push("All");
    this.fileTypes = [
      ...this.fileTypes,
      ...response.fileTypes
        .map((v: any) => v.assetType)
        .filter((item: any) => item !== null && item !== undefined),
    ];
    this.responseResults = response.total || 0;
    this.initSourceFiles();
  }

  public selectSearchType(value: string): void {
    const sort = this.searchFormGroup.get("sortResults").value;

    if (value === SearchType.Similar) {
      this.searchFormGroup.get("sortResults").disable({ onlySelf: true });
    } else {
      this.searchFormGroup.get("sortResults").enable({ onlySelf: true });
    }

    // If the sort option is not a child of the new search type option change the sort to a valid one
    if (
      !this.sorts
        .find((s: any) => s.value === sort)
        .parents.some((p: any) => value === p)
    ) {
      this.searchFormGroup
        .get("sortResults")
        .setValue(
          this.sorts.find((s: any) => s.parents.some((p: any) => p === value))
            .value
        );
    }
  }

  updateClearSearchVisibility(): void {
    this.showClearSearchIcon = !!this.searchFormGroup
      .get("keyword")
      .value.trim();
  }

  public keywordKeyDown(event: KeyboardEvent): boolean {
    // Dirty hack to circumvent compatibility conflict between Safari/Mac and ng Material design
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  public clearSearch(): void {
    this.searchFormGroup.patchValue({
      keyword: "",
      frameRate: "",
      assetStatus: "",
      fileType: "",
      createdFrom: "",
      createdTo: "",
      searchType: SearchType.Similar,
      sortResults: this.searchSettings.sortingOrder,
      resultsPerPage: this.searchSettings.resultsPerPage,
    });
    this.pageFormGroup.get("searchString").setValue("");
  }

  public getSortOptions(): any[] {
    const searchType: SearchType = this.searchFormGroup.get("searchType").value;

    return this.sorts.filter((s) => s.parents.some((p) => p === searchType));
  }

  public toggleThumbnails(event: selectionChangeEvent): void {
    this.searchSettings.thumbnailsVisible = event.value;
  }

  public changeFrom(from: number, scrollTo?: boolean): void {
    this.from = from;
    this.assets = undefined;
    if (scrollTo) {
      this.titleResults?.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    this.searchAssets().subscribe((response) => {
      this.manageSearchResponse(response);
    });
  }
  public manageAssetInSelection(asset: Asset) {
    const oldSelection = [...this.selectedAssets];
    const index = this.selectedAssets.findIndex(
      (selectedAsset) => selectedAsset.id === asset.id
    );
    if (index >= 0) {
      asset.isSelected = false;
      this.selectedAssets.splice(index, 1);
    } else {
      asset.isSelected = true;
      this.selectedAssets.push(asset);
    }

    this.assetSelectionService.setSelection({
      assets: this.selectedAssets,
    });

    const changeCount = this.selectedAssets.length - oldSelection.length;
    const itemChangedStr =
      changeCount >= 0 ? "namedItemAdded" : "namedItemRemoved";
    this.toolsService
      .translatedSnackBar(itemChangedStr, "undo", {
        replaceValue: Math.abs(changeCount),
        duration: 3000,
      })
      .pipe(
        tap(
          (ref: MatSnackBarRef<SimpleSnackBar>) =>
            (this.assetSelectionSnackBar = ref)
        ),
        switchMap((ref: MatSnackBarRef<SimpleSnackBar>) => ref.onAction()),
        tap(() => {
          this.selectedAssets = oldSelection;
          this.assetSelectionService.setSelection({
            assets: this.selectedAssets,
          });
        }),
        switchMap(() => {
          return this.toolsService
            .translatedSnackBar("namedItemCanceled", null, {
              duration: 3000,
            })
            .pipe(
              tap(
                (ref: MatSnackBarRef<SimpleSnackBar>) =>
                  (this.assetSelectionSnackBar = ref)
              )
            );
        })
      )
      .subscribe();
  }

  public backFromSelection(): void {
    this.location.back();
  }

  private extractBackLabel() {
    this.route.params
      .pipe(
        first(),
        map((params) => {
          this.backLabel = params.backLabel;
        })
      )
      .subscribe();
  }

  public async initSourceFiles() {
    this.originalAssetSelection = this.assetSelectionService.getSelection();
    await this.initSelection(this.originalAssetSelection, true);
  }

  protected async initSelection(
    assetSelection: Selection,
    showSelectionNavigation = false,
    data?: any
  ) {
    if (data && data.assetsSelection && showSelectionNavigation) {
      this.isAssetSelection = true;
    }

    this.selectedAssets = assetSelection
      ? assetSelection.assets
      : this.selectedAssets;

    // update selectedAsset in assets source.,
    if (this.selectedAssets.length) {
      const assetIndex = this.assets.findIndex(
        (assets) => assets.id === this.selectedAssets[0].id
      );
      if (assetIndex >= 0) {
        this.assets[assetIndex].isSelected = true;
      }
    }
  }

  get isAnyAssetSelected(): boolean {
    return (
      this.assetSelectionService.getSelection()?.assets.length > 0 || false
    );
  }

  public async test(asset: Asset) {}

  public isRemovable(asset: Asset): boolean {
    return asset.status === Status.Completed && !asset.isIngestionFailed
      ? true
      : false;
  }

  public deleteAssets(asset: Asset) {
    const dialogRef = this.dialog.open(DialogConfirmCancelDeleteComponent);
    dialogRef.componentInstance.data = {
      title: "asset.dialogConfirmAssetDeletion.titleSingle",
      assets: [asset],
      confirmLabel: "asset.dialogConfirmAssetDeletion.apply",
      cancelLabel: "asset.dialogConfirmAssetDeletion.cancel",
    };
    dialogRef.afterClosed().subscribe((isDeletionConfirmed) => {
      if (isDeletionConfirmed) {
        this.assetService.deleteAsset(asset.id).subscribe(() => {
          this.searchAssets().subscribe((response) => {
            this.manageSearchResponse(response);
          });
        });
      }
    });
  }

  public goToAssetDetails(asset: Asset): void {
    this.router.navigate([
      "assets/details",
      (<any>asset).id,
      {
        backLabel: "asset.backTo",
      },
    ]);
  }
}
