import { Asset } from "@models/asset/asset.model";
import { FormPageService } from "~/app/base/formPage.service";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Lexicon } from "@models/lexicon/lexicon.model";
import { UploadAuthorization } from "@models/shared/upload";
import {
  Project,
  ProjectsDto,
  ProjectStatus,
  VoiceType,
} from "@models/textToSpeech/project.model";
import {
  CreateAndStartProduction,
  KeyValuePair,
} from "@models/textToSpeech/textToSpeech.model";
import { Status, UploadAuthorizationBody } from "@models/upload/file.model";
import { User } from "@models/user/user.model";
import { TranslateService } from "@ngx-translate/core";
import { filter, forkJoin, of, tap } from "rxjs";
import { DurationFormatterService } from "~/app/shared/duration-formatter.service";
import { FileService } from "~/app/shared/file.service";
import {
  MultiPartUploadHandler,
  Notification,
} from "~/app/shared/multi-part-upload-handler";
import { ProjectService } from "~/app/textToSpeech/project.service";
import { TextToSpeechService } from "~/app/textToSpeech/textToSpeech.service";
import { UserHelperService } from "~/app/shared/userHelper.service";
import { noWhitespaceValidator } from "~/app/shared/validators";
import { environment } from "~/environments/environment";
import { newUuid } from "~/utils/newUuid";
import {
  getTimeInSecondsFromFrameRate,
  getTimeInSecondsFromMillis,
} from "~/utils/project";
import { DiscardProjectConfirmationComponent } from "../discard-project-confirmation/discard-project-confirmation.component";
import { ManagementService } from "~/app/management/management.service";
import { encodeS3URI } from "~/utils/encodeS3URI";
import { ProjectTemplate } from "@models/template/template.model";
import { UploadFileComponent } from "~/app/shared/components/upload-file/upload-file.component";
import { ScriptWritingComponent } from "../script-writing/script-writing.component";
import { CatalogAssetSelectionService } from "~/app/shared/catalog-assets-selection.service";
import { AssetService } from "~/app/asset/asset.service";
import { ConnectWebSocketService } from "../../connectWebSocket.service";
import { getDefaultTeamsNumberFromEnvironment } from "~/utils/defaultTeamsNumberByEnv";

export interface FileUploadContext {
  file: File;
  assetId: string; // unique projectName
  upload: {
    remainingTime: number; // estimated remaining time in millis before upload is finished
    status: string; // as red or orange or green
    message: string; // progress (as %) or checksum evaluation, or (network) error ...
    progress: number;
  };
  multiPartUploadHandler?: MultiPartUploadHandler;
  authorization?: UploadAuthorization;
  projectName: string;
  isValidated?: boolean;
  isEdited?: boolean; // edit project scenario
  isDeleted?: boolean; // for undo
}

export interface ProjectDataBackup {
  voice: string;
  lexicon: string;
  speed: number;
  automatedSpeedRate: number;
  frameRate: number;
  isAutomatedSpeed: boolean;
}

@Component({
  selector: "app-create-project",
  templateUrl: "./create-project.component.html",
  styleUrls: ["./create-project.component.scss"],
})
export class CreateProjectComponent implements OnInit {
  @ViewChild(UploadFileComponent, { static: false })
  private uploadFileComponent: UploadFileComponent;

  @ViewChild(ScriptWritingComponent, { static: false })
  private scriptWritingComponent: ScriptWritingComponent;

  @ViewChild("stepper", { static: true })
  private stepper: MatStepper;

  @Input() project: Project;
  @Input() isNotEditable: boolean;
  @Input() errorMessage: string;
  @Input() pageId: number;

  public uploadCompleted = false;
  public status = Status;
  public fileUploadContexts: FileUploadContext[] = [];
  public filesAllowedWarning = false;
  public languages: KeyValuePair[];
  public regions: KeyValuePair[];

  public voices: any[];
  public voiceStyle: string[];
  public userName: string;
  public disableControl = false;
  public lexicons: Lexicon[];
  public usersToNotify: User[];
  public cancelledAssetIds: string[];

  public spinner = false;
  public variableSpeedWarning = false;
  public projectReview: any;
  public formGroup: UntypedFormGroup;
  public durationTextMask = {
    mask: [/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/],
  };

  public projectId: number;
  public pageTitle: string;
  public mainButtonName = "createProject.continueButton";
  public mainButtonText = "createProject.continueButton";
  public projectFilesAttached: any[] = [];

  public uploadSingleFile = false;
  public recentlyDeletedFile: FileUploadContext;
  private userEnteredProjectName = "";
  public silenceWarningMessage: string;
  public nonDropFileWarningMessage: string;
  public projectTemplate: ProjectTemplate[];
  public authorizedExtensions = "";
  private backedProjectTemplate: ProjectTemplate[] = [];
  private selectedPresetTemplate: ProjectTemplate;
  private projectDataBackup: ProjectDataBackup;
  private allowXmlOnly = false;
  public showProjectSection = true;
  public selectedAsset: Asset;
  public isProduction = environment.production;
  public retakesGenerationStoredInDb = false;

  @ViewChild("audioSource", { static: true }) audioSource: ElementRef;
  microphoneList: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    protected fileService: FileService,
    private translateService: TranslateService,
    private durationFormatterService: DurationFormatterService,
    protected http: HttpClient,
    private textToSpeechService: TextToSpeechService,
    private managementService: ManagementService,
    private userHelperService: UserHelperService,
    private router: Router,
    private dialog: MatDialog,
    private cdk: ChangeDetectorRef,
    private formPageService: FormPageService,
    private activatedRoute: ActivatedRoute,
    private assetSelectionService: CatalogAssetSelectionService,
    private assetService: AssetService,
    private connectWebSocketService: ConnectWebSocketService
  ) {
    this.userHelperService.sharedUserName.subscribe(
      (username) => (this.userName = username)
    );
    this.languages = environment.project.languages.list;
    const regionKeyValuePairMaster = environment.project.regions.list.find(
      (element) => {
        return (
          element.key.toLowerCase() ===
          environment.project.languages.default.toLowerCase()
        );
      }
    );

    if (regionKeyValuePairMaster) {
      this.regions = regionKeyValuePairMaster.value;
    }
  }

  @HostListener("window:unload", ["$event"])
  unload($event: Event) {
    this.confirmCancelUpload();
  }

  async ngOnInit() {
    window.scroll(0, 0);
    this.setupFormControls();
    this.formGroupChangeDetection();
    this.handleProjectEdit();
    this.getAllTemplates();
    this.getUsers();

    // Listen to 'focus' event on the projectName FormControl
    this.formGroup.controls["projectName"].valueChanges.subscribe(
      (value: string) => {
        this.userEnteredProjectName = value;
      }
    );

    this.handleVoicerSettingChange();
    this.handleAutoRestoreLogic();
    this.handleFormGroupValueChanges();
    this.handleSocket();
  }
  handleSocket() {
    this.connectWebSocketService.connect();

    this.connectWebSocketService.socketMessages.subscribe((message: string) => {
      const parsedMessage = this.parseMessage(message);
      if (!parsedMessage) {
        return;
      }
      this.processWebSocketMessage(parsedMessage);
    });
  }

  private parseMessage(message: string): any {
    try {
      return JSON.parse(message);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      return null;
    }
  }

  private processWebSocketMessage(messageObj: any): void {
    this.buildReview();
    this.buildProject();

    if (this.isMatchingProject(messageObj)) {
      this.updateRetakesGenerationStatus(
        messageObj.retakesGenerationStoredInDb
      );
    }
  }

  private isMatchingProject(messageObj: any): boolean {
    return (
      messageObj.uniqueProjectName &&
      this.projectReview?.uniqueProjectNames.length > 0 &&
      messageObj.uniqueProjectName === this.projectReview?.uniqueProjectNames[0]
    );
  }

  private updateRetakesGenerationStatus(status: boolean): void {
    this.retakesGenerationStoredInDb = status;
  }

  private handleAutoRestoreLogic(): void {
    this.formPageService
      .shouldAutoRestore(this.activatedRoute.queryParams)
      .pipe(
        filter((isAutoRestore: boolean) => isAutoRestore),
        tap(() => {
          const assets =
            this.assetSelectionService.getSelection()?.assets || [];
          const draftData = sessionStorage.getItem("draftProjectData");
          this.formGroup.patchValue(JSON.parse(draftData));
          this.formPageService.setAutoRestore(false);
          this.projectService.getFileUploadContexts().subscribe((data) => {
            this.fileUploadContexts = data;
          });
          if (assets.length) {
            this.selectedAsset = assets[0];
            this.formGroup
              .get("startTimeCode")
              .patchValue(
                this.selectedAsset?.startTimeCode
                  ? this.selectedAsset?.startTimeCode.replace(";", ":")
                  : "00:00:00:00"
              );
            this.formGroup
              .get("frameRate")
              .patchValue(this.selectedAsset?.frameRate);
            if (this.project) {
              this.project.frameRate = Number(this.selectedAsset?.frameRate);
              this.project.startTimeCode = this.selectedAsset?.startTimeCode
                ? this.selectedAsset?.startTimeCode.replace(";", ":")
                : "00:00:00:00";
            }
          }
          this.onLanguageSelectionChange(this.latestLanguageValue, true);
          if (!this.projectId) {
            this.getVoicesAndLexicons(this.latestLanguageRegionValue, false);
          }
        })
      )
      .subscribe();
  }

  private handleFormGroupValueChanges(): void {
    this.formGroup.valueChanges.subscribe((value) => {
      value.selectedTabIndex = !!this.projectId ? 1 : 0;
      sessionStorage.setItem("draftProjectData", JSON.stringify(value));
    });
  }

  private setSelectedUsersToNotify(): void {
    const selectedUserIds = this.formGroup.get("selectedUsersToNotifyList")
      .value as number[];
    if (selectedUserIds) {
      this.usersToNotify.forEach(
        (user) => (user.isSelected = selectedUserIds.includes(user.id))
      );
    }
  }

  get latestLanguageValue(): string {
    return this.formGroup.get("language").value;
  }

  get latestLanguageRegionValue(): string {
    return `${this.formGroup.get("language").value}-${
      this.formGroup.get("region").value
    }`;
  }

  private getUsers(): void {
    this.managementService
      .getUsersToNotify()
      .subscribe((users) => (this.usersToNotify = users))
      .add(() => {
        if (this.projectId) {
          const existingUsersToNotify = this.project.usersToNotify
            ? this.project.usersToNotify?.split(",").map((i) => Number(i))
            : [];
          existingUsersToNotify.forEach((userId) => {
            this.usersToNotify
              .filter((e) => e.id === userId)
              .map((x) => (x.isSelected = true));
          });
          this.setSelectedUsersToNotify();
          if (this.pageId !== undefined) {
            this.goToScriptWriting();
          }
        }
      });
  }

  private getVoicesAndLexicons(
    languageRegion: string,
    isFromPreset: boolean,
    lexiconId?: number
  ): void {
    this.voices = [];
    this.lexicons = [];
    const voiceObs =
      this.textToSpeechService.getVoicesByLanguageAndRegion(languageRegion);
    const lexiconObs =
      this.projectService.getLexiconsByLanguageAndRegion(languageRegion);
    forkJoin([voiceObs, lexiconObs])
      .subscribe(([voices, lexicons]) => {
        this.voices = voices;
        this.lexicons = lexicons;
      })
      .add(() => {
        if (isFromPreset && lexiconId) {
          const isLexiconFound = this.lexicons.find(
            (lexicon) => lexicon.id === lexiconId
          );
          this.formGroup.patchValue({
            lexicon:
              this.formGroup.controls["lexicon"].pristine ||
              (this.formGroup.controls["lexicon"].dirty &&
                this.formGroup.controls["lexicon"].untouched)
                ? isLexiconFound
                  ? isLexiconFound.fileUri
                  : " "
                : this.formGroup.get("lexicon").value,
          });
        }
        this.spinner = false;
      });
  }

  handleVoicerSettingChange() {
    this.formGroup.controls["voiceSettingType"].valueChanges.subscribe(
      (value: string) => {
        if (value === VoiceType.Voicer) {
          this.formGroup.controls["voice"].clearValidators();
        } else {
          this.formGroup.controls["voice"].setValidators(Validators.required);
        }
        this.formGroup.controls["voice"].updateValueAndValidity();
      }
    );
  }

  onSyntheticVoiceClick() {
    this.formGroup.controls["voiceSettingType"].setValue(VoiceType.Synthetic);
  }

  onVoiceChange(value: string) {
    const voiceDetail = this.voices.find((voice) => {
      return voice.shortName === value;
    });
    this.voiceStyle = voiceDetail.styleList
      ? voiceDetail.styleList.split(",")
      : [];
  }

  formGroupChangeDetection() {
    this.formGroup.valueChanges.subscribe((x) => {
      if (this.isAllFilesUploaded) {
        this.disableControl = !this.formGroup.valid;
      }
    });
  }

  editCommonLogic() {
    this.editProject(); // edit project
    this.projectValueBackUp();
  }

  handleProjectEdit() {
    if (!this.project) {
      this.pageTitle = "createProject.title";
      this.formGroup.patchValue({ voiceSettingType: VoiceType.Synthetic });
      this.formPageService
        .shouldAutoRestore(this.activatedRoute.queryParams)
        .pipe(
          filter((isAutoRestore: boolean) => !isAutoRestore),
          tap(() => {
            this.getVoicesAndLexicons(this.latestLanguageRegionValue, false);
          })
        )
        .subscribe();
    } else {
      this.projectId = this.project.id;
      this.mainButtonText =
        this.project.status === ProjectStatus.WaitForTTML
          ? "createProject.continueButton"
          : "createProject.restartButton";
      if (this.project.videoAssetId) {
        this.assetService
          .getAsset(this.project.videoAssetId)
          .subscribe((asset) => {
            this.selectedAsset = asset?.id ? asset : null;
            this.editCommonLogic();
          });
      } else {
        this.editCommonLogic();
      }
    }
  }

  projectValueBackUp() {
    this.projectDataBackup = {
      voice: this.project.voice.split("/")[0],
      lexicon: this.project.lexiconUri,
      speed: this.project.speedRate,
      automatedSpeedRate: this.project.automatedSpeedRate,
      frameRate: this.project.frameRate,
      isAutomatedSpeed: this.project.isAutomatedSpeedRate,
    };
  }

  // projectParameters
  setupFormControls() {
    this.formGroup = this.formBuilder.group({
      projectName: ["", noWhitespaceValidator],
      templateName: [""],
      language: [
        this.projectId ? "" : environment.project.languages.default,
        Validators.required,
      ],
      region: [
        this.projectId ? "" : this.regions[0].value,
        Validators.required,
      ],
      voice: ["", Validators.required],
      voiceStyle: [""],
      lexicon: [" "],
      startTimeCode: ["00:00:00:00", Validators.required],
      speed: [environment.project.speedRate.default, Validators.required],
      frameRate: ["", Validators.required],
      automatedSpeedRate: [
        environment.project.maximumSpeedRate.default,
        Validators.required,
      ],
      isAutomatedSpeed: [true, Validators.required],
      isAutomatedMix: [false],
      usersToNotify: [],
      audioInputDevice: [],
      voiceSettingType: [""],
      voicerReadingSpeed: [190, Validators.required],
      automatedSoundCorrection: [true],
      selectedTabIndex: [0],
      uniqueProjectName: [""],
    });

    this.formGroup.get("lexicon").valueChanges.subscribe((value) => {
      if (!value) {
        // If the value is null (i.e., "None" is selected), reset to pristine
        this.formGroup.get("lexicon").markAsPristine();
      }
    });
  }

  /* when we change the language , region need to be changed,
  so, region will be pulled out based on the language .*/
  onLanguageSelectionChange(
    objectValue: string,
    isPreset: boolean,
    lexiconId?: number
  ) {
    const selectedLanguage = environment.project.regions.list.find(
      (element) => {
        return element.key.toLowerCase() === objectValue.toLowerCase();
      }
    );

    if (selectedLanguage) {
      this.regions = selectedLanguage.value;
      if (!isPreset) {
        this.formGroup.patchValue({
          region: this.project ? this.project.region : this.regions[0].value, // the first item of the region list will be set by default
        });
      } else {
        this.formGroup.patchValue({
          region: this.formGroup.get("region").value, // the first item of the region list will be set by default
        });
      }
      this.onRegionSelectionChange(
        this.formGroup.get("region").value,
        isPreset,
        lexiconId
      );
    }
  }

  /* Based on language-region fetch the voices.*/
  onRegionSelectionChange(
    region: string,
    isPreset: boolean,
    lexiconId?: number
  ) {
    this.spinner = true;
    const languageRegion = this.formGroup.get("language").value + "-" + region;
    if (!isPreset) {
      this.formGroup.patchValue({ voice: "", lexicon: " " });
    }
    this.getVoicesAndLexicons(languageRegion, isPreset, lexiconId);
  }

  get isVoicerSelected(): boolean {
    return this.formGroup.get("voiceSettingType").value === VoiceType.Voicer;
  }

  onUserToNotifyChange(userId: number) {
    this.usersToNotify
      .filter((e) => e.id === userId)
      .map((x) => (x.isSelected = true));
    this.formGroup.patchValue({
      selectedUsersToNotifyList: this.usersToNotify
        .filter((user) => user.isSelected)
        .map((user) => user.id),
    });
  }

  removeUserFromUsersToNotify(userId: number) {
    this.usersToNotify
      .filter((e) => e.id === userId)
      .map((x) => (x.isSelected = false));
    this.formGroup.patchValue({
      selectedUsersToNotifyList: this.usersToNotify
        .filter((user) => user.isSelected)
        .map((user) => user.id),
    });
  }

  public onAutomatedSpeedChange() {
    this.variableSpeedWarning = false;
    if (
      this.formGroup.get("isAutomatedSpeed").value &&
      this.formGroup.get("automatedSpeedRate").value <=
        this.formGroup.get("speed").value
    ) {
      this.variableSpeedWarning = true;
    }
  }

  validateStartTimeCode() {
    const startTimeCodeValue = this.formGroup.get("startTimeCode").value;
    if (startTimeCodeValue.includes("_")) {
      this.formGroup.patchValue({
        startTimeCode: startTimeCodeValue.replaceAll("_", "0"),
      });
    }
  }

  getEnvironmentName(): string {
    const apiUrl = environment.phoenixApiUrl;
    const prefix = "https://api-";
    const suffix = ".difuzevox.com/api/";

    // Extract the substring between prefix and suffix
    if (apiUrl.includes(prefix) && apiUrl.includes(suffix)) {
      return apiUrl.substring(
        apiUrl.indexOf(prefix) + prefix.length,
        apiUrl.indexOf(suffix)
      );
    }

    console.log("ERROR !: un-identified environment, how come ?");
    return "unknown";
  }

  buildReview() {
    this.onStartTimeCodeChange();
    const defaultTeams = getDefaultTeamsNumberFromEnvironment(environment.name);
    const voiceName = this.formGroup.get("voice").value;
    const style = this.formGroup.get("voiceStyle").value;
    const voiceDetail = this.voices.find((voice) => {
      return voice.shortName === voiceName;
    });

    this.projectReview = {
      projectNames: [],
      uniqueProjectNames: [],
      language: this.formGroup.get("language").value,
      region: this.formGroup.get("region").value,
      voice: voiceName
        ? voiceName +
          "/" +
          voiceDetail?.maskedShortName +
          "/" +
          voiceDetail?.createdBy +
          "/" +
          style
        : "",
      voiceSetting: this.formGroup.get("voiceSettingType").value,
      voicerReadingSpeed: this.formGroup.get("voicerReadingSpeed").value,
      lexiconUri: this.formGroup.get("lexicon").value,
      startTimeCode: this.formGroup.get("startTimeCode").value,
      speedRate: this.formGroup.get("speed").value,
      usersToNotify: this.usersToNotify
        .filter((e) => e.isSelected === true)
        .map(({ id }) => id)
        .join(","),
      teamsToNotify: defaultTeams, // "Montreal". By default. We need a team
      isRestarting: this.project ? true : false,
      isAutomatedSpeed: this.isVoicerSelected
        ? false
        : this.formGroup.get("isAutomatedSpeed").value,
      automatedSpeedRate: this.formGroup.get("automatedSpeedRate").value,
      usersToNotifyEmails: this.usersToNotify.filter(
        (e) => e.isSelected === true
      ),
      isAutomatedMix: this.isVoicerSelected
        ? false
        : this.formGroup.get("isAutomatedMix").value,
      frameRate: this.formGroup.get("frameRate").value,
      maskedVoice: voiceName ? voiceDetail?.maskedShortName : "",
      languageName: this.languages.find((language) => {
        return language.value === this.formGroup.get("language").value;
      }).key,
      lexiconName:
        this.formGroup.get("lexicon").value?.trim() === "" ||
        this.isVoicerSelected
          ? ""
          : this.lexicons.find((lexicon) => {
              return lexicon.fileUri === this.formGroup.get("lexicon").value;
            })?.name,
      fileDetails: [],
      templateName: this.selectedPresetTemplate
        ? this.selectedPresetTemplate.name
        : "",
      stereoFilePath: "",
      clientId: this.selectedPresetTemplate
        ? this.selectedPresetTemplate.clientId
        : null,
      templateId: this.selectedPresetTemplate
        ? this.selectedPresetTemplate.id
        : null,
      lexiconId:
        this.formGroup.get("lexicon").value?.trim() === "" ||
        this.isVoicerSelected
          ? null
          : this.lexicons.find((lexicon) => {
              return lexicon.fileUri === this.formGroup.get("lexicon").value;
            })?.id,
      humanVoiceRecorderId:
        this.formGroup.get("audioInputDevice").value || null,
      videoAssetId: !!this.selectedAsset
        ? this.selectedAsset.id
        : this.project?.videoAssetId || null,
      automatedSoundCorrection: this.formGroup.get("automatedSoundCorrection")
        .value,
      isGeneratedTtmlFile: this.project
        ? this.project.isGeneratedTtmlFile
        : this.isXmlFileUploaded,
    };

    // build the project/files-attached section - create project scenario only
    if (!this.projectId) {
      this.getProjectFilesAttached();
    }
  }

  getProjectFilesAttached() {
    this.projectFilesAttached = [];
    if (this.fileUploadContexts.length === 1) {
      this.projectFilesAttached.push({
        projectName: this.formGroup.get("projectName").value,
        fileName: [this.fileUploadContexts[0].file.name],
        assetId: this.fileUploadContexts[0].assetId,
      });
    } else if (
      this.fileUploadContexts.length === 2 &&
      this.fileUploadContexts[0].file.name.split(".").reverse()[0] !==
        this.fileUploadContexts[1].file.name.split(".").reverse()[0]
    ) {
      const fileNames = this.fileUploadContexts.map((files) => {
        return files.file.name;
      });
      this.projectFilesAttached.push({
        projectName: this.formGroup.get("projectName").value,
        fileName: [...fileNames],
        assetId: this.fileUploadContexts[0].assetId,
      });
    } else {
      this.fileUploadContexts.forEach((file) => {
        this.projectFilesAttached.push({
          projectName: file.projectName,
          fileName: [file.file.name],
          assetId: file.assetId,
        });
      });
    }
  }

  public onFileSelectedFromScriptWriting($event: any) {
    if ($event.uniqueProjectName) {
      const removedFile = this.fileUploadContexts.find(
        (file) => file.assetId === $event.uniqueProjectName
      );
      this.cancelSingleFileUpload(removedFile);
    }

    this.onFileSelected($event.fileData);
  }

  // fileUpload
  public async onFileSelected($event: any): Promise<void> {
    this.uploadCompleted = false;
    this.buildFileUploadContexts($event.target.files as unknown as FileList);
    await this.calculateSilenceOnCreate();
    // this.addControls(); // adds projectName controls on the go.
    this.cdk.detectChanges();
    this.addProjectNameValidation(); // add projectName validation
    if (this.isProjectCreationValid()) {
      // trying to create project with wav and ttml and more than one
      alert(this.translateService.instant("createProject.upload.mixUpload"));
    }
    await this.prepareFiles();
    if (
      this.scriptWritingComponent &&
      this.scriptWritingComponent.uploadFileComponent?.fileUploadInput
    ) {
      this.scriptWritingComponent.uploadFileComponent.fileUploadInput.nativeElement.value =
        "";
    } else if (this.uploadFileComponent?.fileUploadInput) {
      this.uploadFileComponent.fileUploadInput.nativeElement.value = "";
    }
    this.startUploadingFiles();
  }

  isProjectCreationValid(): boolean {
    const isMixProject = this.fileUploadContexts.filter(
      (files) => !files.isValidated
    );
    return isMixProject.length > 0;
  }

  hasFileAttached() {
    return this.fileUploadContexts.filter((x) => !x.isDeleted).length > 0;
  }

  addProjectNameValidation() {
    const filesSupportingUploadScenarios = this.fileUploadContexts.filter(
      (file) => file.isValidated && !file.isDeleted
    );
    if (
      (filesSupportingUploadScenarios &&
        filesSupportingUploadScenarios.length) === 1 ||
      (filesSupportingUploadScenarios.length === 2 &&
        filesSupportingUploadScenarios[0].file.type !==
          filesSupportingUploadScenarios[1].file.type)
    ) {
      this.formGroup.controls["projectName"].setValidators([
        Validators.required,
        noWhitespaceValidator,
      ]);
      if (!this.projectId) {
        // project creation scenario
        const projectName =
          this.formGroup.get("projectName").value.trim() === "";
        if (projectName) {
          this.patchProjectName();
        }
      }
    } else {
      if (this.formGroup.get("projectName").value !== "" && !this.projectId) {
        // create project-scenario
        if (
          filesSupportingUploadScenarios.length > 0 ||
          this.userEnteredProjectName.trim() === ""
        ) {
          this.formGroup.patchValue({ projectName: "" });
          this.userEnteredProjectName = "";
        }
      }
      this.formGroup.controls["projectName"].clearValidators();
    }
    this.formGroup.controls["projectName"].updateValueAndValidity();
  }

  // during project creation
  patchProjectName() {
    // project creation,if ttml it already uploaded, uploading wav should override the projectName
    if (this.userEnteredProjectName.trim() === "") {
      const isWAVExist = this.fileUploadContexts.filter(
        (x) => x.file.type.split("/")[1] === "wav" && !x.isDeleted
      )[0];
      isWAVExist
        ? this.formGroup.patchValue({
            projectName: isWAVExist.file.name.substr(
              0,
              isWAVExist.file.name.lastIndexOf(".")
            ),
          })
        : this.formGroup.patchValue({
            projectName: this.fileUploadContexts[0].isDeleted
              ? this.fileUploadContexts[1].file.name.substr(
                  0,
                  this.fileUploadContexts[1].file.name.lastIndexOf(".")
                )
              : this.fileUploadContexts[0].file.name.substr(
                  0,
                  this.fileUploadContexts[0].file.name.lastIndexOf(".")
                ),
          });
    }
  }

  // adds text box controls which all us to rename the projects, in case of bulk project creation i.e., more than one at a time
  addControls() {
    this.fileUploadContexts.forEach((file) => {
      this.formGroup.addControl(
        `${file.file.name}-${file.assetId}`,
        this.formBuilder.control("", [Validators.required])
      );
    });
  }

  isProjectNameToBeDisplayed(): boolean {
    const filesSupportingUploadScenarios = this.fileUploadContexts.filter(
      (file) => file.isValidated
    );
    if (
      (filesSupportingUploadScenarios &&
        filesSupportingUploadScenarios.length) === 1 ||
      (filesSupportingUploadScenarios.length === 2 &&
        filesSupportingUploadScenarios[0].file.type !==
          filesSupportingUploadScenarios[1].file.type)
    ) {
      return false;
    } else if (this.projectId) {
      return false;
    } else {
      return true;
    }
  }

  /** temporary situation to offer display of files although checksum happens under the hood
   *  for each file one by one. This method will be removed when checksums will be computed
   *  progressively along with parallel uploads of chunks
   */
  private buildFileUploadContexts(files: FileList) {
    for (let index = 0; index < files.length; index++) {
      if (
        this.authorizedFileExtensions.includes(
          "." + files[index].name.split(".").pop().toLowerCase()
        )
      ) {
        this.filesAllowedWarning = false;
        const fileContext: FileUploadContext = {
          file: files[index],
          assetId: "",
          upload: {
            remainingTime: Infinity,
            status: "",
            message: "",
            progress: 0,
          },
          projectName: "",
        };
        !this.projectId
          ? this.createProjectValidation(fileContext)
          : this.editProjectValidation(fileContext);
      } else {
        this.filesAllowedWarning = true;
        return;
      }
    }
  }

  createProjectValidation(fileContext: FileUploadContext) {
    // restrict user-file selection/upload
    const filterDifferentTypes = this.fileUploadContexts.filter(
      (x) => x.file.type !== fileContext.file.type && !x.isDeleted
    );
    const filterSameTypes = this.fileUploadContexts.filter(
      (x) => x.file.type === fileContext.file.type && !x.isDeleted
    );
    if (
      (filterDifferentTypes && filterDifferentTypes.length < 1) ||
      (filterSameTypes.length < 1 && filterDifferentTypes.length <= 1)
    ) {
      fileContext.isValidated = true;
      fileContext.upload.message = this.translateService.instant(
        "createProject.upload.inProgress"
      );
      fileContext.upload.status = Status.Uploading;
    } else {
      fileContext.isValidated = false;
    }
    fileContext.assetId = newUuid();
    fileContext.isEdited = true;
    fileContext.projectName = fileContext.file.name.substr(
      0,
      fileContext.file.name.lastIndexOf(".")
    );
    if (
      this.fileUploadContexts.length === 1 &&
      this.fileUploadContexts[0].file.type !== fileContext.file.type
    ) {
      fileContext.assetId = this.fileUploadContexts[0].assetId;
    }
    this.handleFileName(fileContext);
    this.fileUploadContexts.push(fileContext);
  }

  handleFileName(fileContext: FileUploadContext) {
    const fileName = fileContext.file.name.substr(
      0,
      fileContext.file.name.lastIndexOf(".")
    );
    const filter = this.fileUploadContexts.filter((context) =>
      context.file.name.includes(fileName)
    );
    if (filter.length > 0) {
      fileContext.projectName = `${fileName}_${filter.length}`;
      fileContext.file = new File(
        [fileContext.file],
        `${fileName}_${filter.length}.${fileContext.file.name.substr(
          fileContext.file.name.lastIndexOf(".") + 1,
          fileContext.file.name.length
        )}`,
        {
          type: fileContext.file.type,
        }
      );
    }
  }

  // edit scenario
  editProjectValidation(fileContext: FileUploadContext) {
    const filterDifferentTypes = this.fileUploadContexts.filter(
      (x) =>
        x.file.name.split(".").reverse()[0] !==
          fileContext.file.type.split("/")[1] && !x.isDeleted
    );
    const filterSameTypes = this.fileUploadContexts.filter(
      (x) =>
        x.file.name.split(".").reverse()[0] ===
          fileContext.file.type.split("/")[1] && !x.isDeleted
    );
    if (filterDifferentTypes.length === 0 || filterSameTypes.length === 0) {
      // double validate files of similar types, as this is the update scenario, we should not allow to upload files of similar types
      if (filterSameTypes.length === 0) {
        fileContext.isValidated = true;
      }
    }
    fileContext.assetId = this.project.uniqueProjectName;
    fileContext.isEdited = true;
    fileContext.projectName = fileContext.file.name.substr(
      0,
      fileContext.file.name.lastIndexOf(".")
    );
    this.handleFileName(fileContext);
    this.fileUploadContexts.push(fileContext);
  }

  public setAuthorizedFileExtensions(): void {
    const extensions = this.allowXmlOnly
      ? environment.project.authorizedFileExtensions.filter(
          (ex) => ex === ".xml"
        )
      : environment.project.authorizedFileExtensions.filter(
          (ex) => ex !== ".xml"
        );
    this.authorizedExtensions = extensions.join(", ");
  }

  public get authorizedFileExtensions(): string {
    return environment.project.authorizedFileExtensions.join(", ");
  }

  public dragOverHandler($event: DragEvent): boolean {
    $event.preventDefault();
    return false;
  }

  public dragLeaveHandler($event: DragEvent): boolean {
    $event.preventDefault();
    return false;
  }

  public setShowProjectSection() {
    this.showProjectSection = !this.showProjectSection;
    this.pageId = undefined;
  }

  public onDropHandlerFromScriptWriting($event: any) {
    if ($event.uniqueProjectName) {
      const removedFile = this.fileUploadContexts.find(
        (file) => file.assetId === $event.uniqueProjectName
      );
      this.cancelSingleFileUpload(removedFile);
    }
    this.dropHandler($event.fileData);
  }

  public async dropHandler($event: DragEvent): Promise<boolean> {
    $event.preventDefault();
    if (this.canSelectFiles) {
      this.uploadCompleted = false;
      this.buildFileUploadContexts($event.dataTransfer.files);
      // this.addControls(); // add projectName controls on the go.
      this.cdk.detectChanges();
      this.addProjectNameValidation(); // add projectName validation
      if (this.isProjectCreationValid()) {
        // trying to create project with wav and ttml and more than one
        alert(this.translateService.instant("createProject.upload.mixUpload"));
      }
      await this.prepareFiles();
      this.startUploadingFiles();
    }
    return of(false).toPromise();
  }

  public get canSelectFiles() {
    return (
      this.fileUploadContexts.length === 0 ||
      (this.fileUploadContexts.length > 0 &&
        this.fileUploadContexts.length ===
          this.fileUploadContexts.filter(
            (fuc) => fuc.upload.status === Status.UploadSuccess
          ).length)
    );
  }

  public get canDisplayFilesListing() {
    return this.fileUploadContexts.length > 0;
  }

  public get isXmlFileUploaded() {
    return (
      this.canDisplayFilesListing &&
      this.fileUploadContexts.some((fileUploadContext) =>
        fileUploadContext.file.name.includes(".xml")
      )
    );
  }

  /* this separation is temporary as long as checksum is computed from the beginning */
  public async prepareFiles(): Promise<void> {
    this.markExceedingFilesSizeByWarning();
    await this.preProcessUnExceedingFilesSize();
  }

  private async preProcessUnExceedingFilesSize() {
    for (let index = 0; index < this.fileUploadContexts.length; index++) {
      const fileUploadContext = this.fileUploadContexts[index];
      if (
        fileUploadContext.file.size <= environment.project.maximumFileSize &&
        fileUploadContext.isValidated &&
        fileUploadContext.upload.status !== Status.UploadSuccess
      ) {
        await this.preProcessFileUploadContext(fileUploadContext);
      }
    }
  }

  public markExceedingFilesSizeByWarning() {
    for (let index = 0; index < this.fileUploadContexts.length; index++) {
      const fileUploadContext = this.fileUploadContexts[index];
      if (fileUploadContext.file.size > environment.project.maximumFileSize) {
        fileUploadContext.upload.status = Status.FileSizeExceedingFailure;
        fileUploadContext.upload.message = this.translateService.instant(
          "createProject.upload.maxFileSizeReached"
        );
        fileUploadContext.upload.message =
          fileUploadContext.upload.message.replace(
            "{maxFileSizeReached}",
            environment.project.maximumFileSize as unknown as string
          );
      }
    }
  }

  public cancelSingleFileUpload(fileUploadContext: FileUploadContext) {
    fileUploadContext.isDeleted = true;
    this.addProjectNameValidation();
    this.deleteFiles(fileUploadContext);
    this.recentlyDeletedFile = fileUploadContext;
  }

  undo(fileUploadContext: FileUploadContext) {
    fileUploadContext.isDeleted = false;
    this.addProjectNameValidation();
  }

  // schedule deletion of files if no action is performed after cancellation
  deleteFiles(fileUploadContext: FileUploadContext) {
    this.spinner = true;
    setTimeout(async () => {
      if (fileUploadContext.isDeleted) {
        this.formGroup.removeControl(
          `${fileUploadContext.file.name}-${fileUploadContext.assetId}`
        );
        if (!this.project) {
          this.cancel(fileUploadContext);
          this.spinner = false;
        } else {
          const index = this.fileUploadContexts.findIndex(
            (fuc) => fuc.file.name === fileUploadContext.file.name
          );
          this.fileUploadContexts.splice(index, 1);
        }
        if (fileUploadContext.upload.status === Status.UploadSuccess) {
          await this.deleteFileFromS3(
            `${fileUploadContext.assetId}/${fileUploadContext.file.name}`
          );
        }
      }
    }, environment.snackBarDurationLong + 500);
  }

  // remove file from fileUpload list and abort upload if in progress..
  private cancel(fileUploadContext: FileUploadContext): void {
    const index = this.fileUploadContexts.findIndex(
      (fuc) => fuc.file.name === fileUploadContext.file.name
    );
    if (index >= 0) {
      this.abortUploadingFile(fileUploadContext);
      this.fileUploadContexts.splice(index, 1);
    }
    if (this.areFilesAllUploaded) {
      this.processUploadCompleted();
    }
  }

  public async abortUploadingFile(
    fileUploadContext: FileUploadContext
  ): Promise<void> {
    if (fileUploadContext.multiPartUploadHandler) {
      await fileUploadContext.multiPartUploadHandler.notifier.unsubscribe();
      await fileUploadContext.multiPartUploadHandler.abortMultipartUpload();
      delete fileUploadContext.multiPartUploadHandler;
    }
    fileUploadContext.upload.message = " ";
    fileUploadContext.upload.status = Status.Uploading;
    fileUploadContext.upload.progress = 0;
  }

  // delete files from s3
  public async deleteFileFromS3(cancelledFileAssetId: string): Promise<void> {
    await this.deleteFile(cancelledFileAssetId);
    this.updateScriptWritingComponent();
    this.updateProjectReplies();
    this.buildReview();
    this.spinner = true;
    this.buildProject();
    this.saveProjectReview();
  }

  private async deleteFile(cancelledFileAssetId: string): Promise<void> {
    await this.projectService.discardFile([cancelledFileAssetId]).toPromise();
  }

  private updateScriptWritingComponent(): void {
    if (this.scriptWritingComponent) {
      this.scriptWritingComponent.spinner = true;
      this.scriptWritingComponent.project.replies = [];
      this.scriptWritingComponent.checkForReplies();
    }
  }

  private updateProjectReplies(): void {
    if (!this.isXmlFileUploaded && !this.scriptWritingComponent) {
      if (!this.project) {
        this.project = {} as Project;
      }
      this.project.replies = [];
    }
  }

  private saveProjectReview(): void {
    this.projectService.save(this.projectReview).subscribe((data) => {
      this.formGroup.patchValue({
        uniqueProjectName: data.data.uniqueProjectName,
      });
      this.project = data.data;
      this.projectId = data.data.id;
      this.spinner = false;
    });
  }

  public confirmCancelUpload() {
    for (let index = 0; index < this.fileUploadContexts.length; index++) {
      const fileUploadContext = this.fileUploadContexts[index];
      this.cancel(fileUploadContext);
      if (fileUploadContext.upload.status === Status.UploadSuccess) {
        this.cancelledAssetIds.push(
          `${fileUploadContext.assetId}/${fileUploadContext.file.name}`
        );
      }
      index--; // since this.fileUploadContexts.length has decremented
    }
  }

  public get unRemovedFileUploadContexts() {
    return this.fileUploadContexts.filter((files) => !files.isDeleted);
  }

  /**
   * Pre-process file upload
   * @param fileUploadContext
   * @private
   */
  private async preProcessFileUploadContext(
    fileUploadContext: FileUploadContext
  ) {
    const fileExtension = fileUploadContext.file.name.split(".").reverse()[0];
    fileUploadContext.upload.status = Status.Uploading;

    const uploadAuthorizationBody: UploadAuthorizationBody = {
      assetId:
        fileUploadContext.assetId +
        "/" +
        fileUploadContext.file.name.substr(
          0,
          fileUploadContext.file.name.lastIndexOf(".")
        ),
      fileExtension,
      fileSize: fileUploadContext.file.size,
      numberOfFiles: this.fileUploadContexts.length,
      uploadType: "fileUpload",
      projectData: JSON.stringify({
        name: encodeURIComponent(this.formGroup.get("projectName").value),
        language: this.formGroup.get("language").value,
        frameRate: this.formGroup.get("frameRate").value,
        startTimeCode: this.formGroup.get("startTimeCode").value,
        usersToNotify: this.usersToNotify
          ?.filter((e) => e.isSelected === true)
          .map(({ id }) => id),
      }),
    };

    fileUploadContext.authorization = await this.fileService
      .createUploadAuthorization(uploadAuthorizationBody)
      .toPromise();

    if (
      fileExtension.toLowerCase() === "xml" &&
      this.project &&
      this.projectReview
    ) {
      this.project.isGeneratedTtmlFile = false;
      this.projectReview.isGeneratedTtmlFile = false;
      await this.deleteFileFromS3(
        `${fileUploadContext.assetId}/${this.project.name}.xml`
      );
    }
  }

  public async startUploadingFiles(): Promise<void> {
    for (let index = 0; index < this.fileUploadContexts.length; index++) {
      const fileUploadContext = this.fileUploadContexts[index];
      if (
        fileUploadContext.upload.progress === 0 &&
        fileUploadContext.isValidated &&
        fileUploadContext.upload.status === Status.Uploading
      ) {
        fileUploadContext.upload.message = "";
        this.startMultiPartUpload(fileUploadContext);
      }
    }
  }

  private startMultiPartUpload(fileUploadContext: FileUploadContext): void {
    fileUploadContext.multiPartUploadHandler = new MultiPartUploadHandler(
      this.fileService,
      this.http,
      {
        notificationHandler: (notification) =>
          this.handleNotification(notification),
        file: fileUploadContext.file,
        uploadAuthorization: fileUploadContext.authorization,
      }
    );
  }

  private get areFilesAllUploaded(): boolean {
    let allFilesUploaded = false;
    if (this.fileUploadContexts.length > 0) {
      allFilesUploaded =
        this.fileUploadContexts.filter(
          (fuc) => fuc.upload.status !== Status.UploadSuccess
        ).length === 0;
    }
    return allFilesUploaded;
  }

  private processUploadCompleted() {
    this.uploadCompleted = true;
    this.projectService.onChangeFileUploadContexts(this.fileUploadContexts);
    if (this.project && this.isXmlFileUploaded) {
      this.project.isGeneratedTtmlFile = false;
      this.projectReview.isGeneratedTtmlFile = false;
    }
  }

  public get isAllFilesUploaded(): boolean {
    const filesUploadedSuccessfully =
      this.fileUploadContexts.length &&
      this.fileUploadContexts.filter(
        (file) => file.upload.status === Status.UploadSuccess
      );
    return filesUploadedSuccessfully.length === this.fileUploadContexts.length;
  }

  checkForReplies() {
    if (this.scriptWritingComponent) {
      this.scriptWritingComponent.spinner = true;
      this.scriptWritingComponent.retriedCount = 0;
      this.scriptWritingComponent.project.replies = [];

      this.scriptWritingComponent.checkForReplies();
    }
  }

  private handleNotification(notification: Notification): void {
    const foundFileUploadContext = this.fileUploadContexts.find(
      (fileUploadContext) =>
        fileUploadContext.file &&
        fileUploadContext.file.name === notification.fileName
    );
    if (!foundFileUploadContext) {
      // might have been removed from the list meanwhile
      return;
    }
    if (notification.status === "progression") {
      foundFileUploadContext.upload.progress = notification.value;
      foundFileUploadContext.upload.status = Status.Uploading;
      foundFileUploadContext.upload.message = "";
    } else if (notification.status === "remainingTime") {
      foundFileUploadContext.upload.remainingTime = notification.value;
      foundFileUploadContext.upload.status = Status.Uploading;
    } else if (notification.status === "completionTime") {
      foundFileUploadContext.upload.message = this.translateService.instant(
        "createProject.upload.completed"
      );
      foundFileUploadContext.upload.status = Status.UploadSuccess;
      this.checkForReplies();
    } else {
      /* if (notification.status === 'networkIssue') */
      const networkIssue = this.translateService.instant(
        "createProject.upload.networkIssueRetry"
      );
      foundFileUploadContext.upload.message = networkIssue.replace(
        "{networkIssueRetryInterval}",
        notification.value.toString()
      );
      foundFileUploadContext.upload.status = Status.UploadFailure;
    }
    if (this.areFilesAllUploaded) {
      this.processUploadCompleted();
    }
  }

  public getFormattedDurationMessage(fileContext: FileUploadContext): string {
    return this.durationFormatterService.getUserReadableMessage(
      fileContext.upload.remainingTime,
      "createProject.upload",
      "remainingTime"
    );
  }

  buildProject() {
    this.disableControl = true; // To avoid multiple clicks of the save button.
    this.projectReview.projectNames = [];
    this.projectReview.uniqueProjectNames = [];
    if (
      this.fileUploadContexts.length === 1 ||
      (this.fileUploadContexts.length === 2 &&
        this.fileUploadContexts[0].file.name.split(".").reverse()[0] !==
          this.fileUploadContexts[1].file.name.split(".").reverse()[0])
    ) {
      this.setProjectNameAndFilePathForSingleProject(); // creating a single project, either by xml/wav or both together
    } else if (this.fileUploadContexts.length) {
      this.setProjectNamesAndFilePaths(); // bulk project creation
    } else {
      this.projectReview.projectNames.push(
        this.formGroup.get("projectName").value
      );
      this.projectReview.uniqueProjectNames = [
        this.project?.uniqueProjectName || this.uniqueProjectName
          ? this.uniqueProjectName
          : newUuid(),
      ];
    }
    const hasWav = this.fileUploadContexts.filter(
      (f) => f.file.name.split(".").reverse()[0] === "wav"
    );

    this.projectReview.stereoFilePath = !!this.selectedAsset
      ? hasWav && hasWav.length > 0
        ? hasWav[0].file.name !==
          this.getExtractedAudioPath.substring(
            this.getExtractedAudioPath.lastIndexOf("/") + 1
          )
          ? hasWav[0].file.name
          : this.getExtractedAudioPath
        : this.getExtractedAudioPath
      : !!this.project?.stereoFilePath
      ? this.project.stereoFilePath
      : hasWav.length > 0
      ? hasWav[0].file.name
      : "";
    this.projectReview.uniqueProjectNames = this.projectId
      ? [this.project.uniqueProjectName]
      : this.projectReview.uniqueProjectNames;
  }

  get getExtractedAudioPath() {
    return this.selectedAsset?.assetProxy.find(
      (proxy) => proxy.type === "audio"
    )?.key;
  }

  // creating more than one project at once
  setProjectNamesAndFilePaths() {
    for (let i = 0; i < this.fileUploadContexts.length; i++) {
      const projectName =
        this.fileUploadContexts.length === 1
          ? this.formGroup.get("projectName").value
          : this.fileUploadContexts[i].projectName;
      this.projectReview.projectNames.push(projectName);
      this.projectReview.fileDetails.push({
        uniqueProjectName: this.fileUploadContexts[i].assetId,
        files: {
          ttmlFilePath:
            this.getFileType(i) === "xml"
              ? this.fileUploadContexts[i].file.name
              : null,
          stereoFilePath:
            this.getFileType(i) === "wav"
              ? this.fileUploadContexts[i].file.name
              : null,
        },
      });
      this.projectReview.uniqueProjectNames.push(
        this.fileUploadContexts[i].assetId
      );
    }
  }

  // single project scenario
  setProjectNameAndFilePathForSingleProject() {
    this.projectReview.projectNames.push(
      this.formGroup.get("projectName").value
    );
    if (this.fileUploadContexts.length === 1) {
      this.projectReview.fileDetails.push({
        uniqueProjectName: this.fileUploadContexts[0].assetId,
        files: {
          ttmlFilePath:
            this.getFileType(0) === "xml"
              ? this.fileUploadContexts[0].file.name
              : null,
          stereoFilePath: !!this.selectedAsset
            ? this.getFileType(0) === "wav"
              ? this.fileUploadContexts[0].file.name !==
                this.getExtractedAudioPath.substring(
                  this.getExtractedAudioPath.lastIndexOf("/") + 1
                )
                ? this.fileUploadContexts[0].file.name
                : this.getExtractedAudioPath
              : this.getExtractedAudioPath
            : this.getFileType(0) === "wav"
            ? this.fileUploadContexts[0].file.name
            : null,
        },
      });
    } else {
      const ttmlAttached = this.fileUploadContexts.filter(
        (f) => f.file.name.split(".").reverse()[0] === "xml"
      );
      const WavAttached = this.fileUploadContexts.filter(
        (f) => f.file.name.split(".").reverse()[0] === "wav"
      );
      this.projectReview.fileDetails.push({
        uniqueProjectName: this.fileUploadContexts[0].assetId,
        files: {
          ttmlFilePath:
            ttmlAttached.length > 0 ? ttmlAttached[0].file.name : null,
          stereoFilePath: !!this.selectedAsset
            ? WavAttached?.length > 0
              ? WavAttached[0].file.name !==
                this.getExtractedAudioPath.substring(
                  this.getExtractedAudioPath.lastIndexOf("/") + 1
                )
                ? WavAttached[0].file.name
                : this.getExtractedAudioPath
              : this.getExtractedAudioPath
            : WavAttached.length > 0
            ? WavAttached[0].file.name
            : null,
        },
      });
    }
    this.projectReview.uniqueProjectNames.push(
      this.projectId
        ? this.project.uniqueProjectName
        : this.uniqueProjectName
        ? this.uniqueProjectName
        : this.fileUploadContexts[0].assetId
    );
  }

  get uniqueProjectName(): string {
    return this.formGroup.get("uniqueProjectName").value;
  }

  projectNameTooLong() {
    alert(this.translateService.instant("createProject.projectNameTooLong"));
    this.spinner = false;
    this.disableControl = false;
  }

  isProjectNameTooLong(projectDto: ProjectsDto): boolean {
    const projectsWithLongProjectNames = projectDto.projectNames.filter(
      (name) => name.length > environment.project.projectNameLength
    );
    return projectsWithLongProjectNames.length > 0;
  }

  setProjectStatus(projectsDto: ProjectsDto) {
    const xmlAttached = this.fileUploadContexts.filter(
      (file) => file.file.name.split(".").reverse()[0] === "xml"
    );
    if (xmlAttached.length > 0) {
      projectsDto.projectStatus = ProjectStatus.UploadingText;
    } else {
      projectsDto.projectStatus = ProjectStatus.WaitForTTML;
    }
  }

  public isOnlyWavUploaded() {
    const wavFilesArray = this.fileUploadContexts.filter((files) => {
      return files.file.type.split("/")[1] === "wav" && !files.isDeleted;
    });
    return (
      wavFilesArray.length ===
      this.fileUploadContexts.filter((e) => !e.isDeleted).length
    );
  }

  discardProject(discardAction: boolean): void {
    this.cancelledAssetIds = [];
    let dialogRef = this.dialog.open(DiscardProjectConfirmationComponent, {
      width: "775px",
      height: "450px",
      data: {
        discard: discardAction,
      },
    });

    dialogRef.afterClosed().subscribe((isCancellationConfirmed: boolean) => {
      if (isCancellationConfirmed) {
        if (discardAction) {
          this.confirmCancelUpload();
          this.projectService.discardFile(this.cancelledAssetIds).subscribe();
          this.router.navigateByUrl("/projects");
        } else {
          this.goToScriptWriting();
        }
      }
      dialogRef = undefined;
      return false;
    });
  }

  public processXmlFile(response: boolean, projectsDto: ProjectsDto) {
    if (response) {
      // No need to wait for response or for exception of ttmlXmlToOutputAudioFile because if any success or exception is handled and updating status in DB.
      const ttmlAttached = this.fileUploadContexts.filter(
        (file) => file.file.name.split(".").reverse()[0] === "xml"
      );
      if (ttmlAttached.length > 0 || projectsDto.replies?.length > 0) {
        this.textToSpeechService
          .convertTtmlIntoOutputAudioFile(projectsDto)
          .subscribe();
      }
    }
  }

  getFileType(index: number): string {
    return this.fileUploadContexts[index].file.name.split(".").reverse()[0] ===
      "wav"
      ? "wav"
      : "xml";
  }

  editProject() {
    this.setFormValues();
    setTimeout(() => {
      // dirty hack to override the ''
      this.formGroup.patchValue({
        voice: this.project.voice.split("/")[0],
      });
    }, 500);
    this.pageTitle = this.project.name;
    if (this.project.ttmlFilePath) {
      this.listEditProjectFiles(
        this.project.ttmlFilePath.substr(
          this.project.ttmlFilePath.lastIndexOf("/") + 1
        )
      );
    }
    if (this.project.stereoFilePath) {
      this.listEditProjectFiles(
        this.project.stereoFilePath.substr(
          this.project.stereoFilePath.lastIndexOf("/") + 1
        )
      );
    }
    this.cdk.detectChanges();
    this.onLanguageSelectionChange(this.project.language, false);
    this.formGroup.patchValue({ lexicon: this.project.lexiconUri });
  }

  // edit project scenario, list files attached to the project
  listEditProjectFiles(fileName: string) {
    const fileContext: FileUploadContext = {
      file: new File([""], fileName),
      assetId: this.project.uniqueProjectName,
      upload: {
        remainingTime: 0,
        status: Status.UploadSuccess,
        message: Status.UploadSuccess,
        progress: 100,
      },
      projectName: fileName,
      isValidated: true,
    };
    const existingContext = this.fileUploadContexts.find(
      (context) =>
        context.assetId === this.project.uniqueProjectName &&
        context.file.name === fileName
    );
    if (!existingContext) {
      this.fileUploadContexts.push(fileContext);
    }
  }

  setFormValues() {
    this.formGroup.patchValue({
      projectName: this.project.name,
      templateName: this.project.templateId,
      language: this.project.language,
      region: this.project.region,
      lexicon: this.project.lexiconUri,
      startTimeCode: this.project.startTimeCode,
      frameRate: this.project.frameRate,
      speed: this.project.speedRate,
      automatedSpeedRate: this.project.automatedSpeedRate,
      isAutomatedSpeed: this.project.isAutomatedSpeedRate,
      isAutomatedMix: this.project.isAutomatedMix,
      usersToNotify: [],
      audioInputDevice: this.project.humanVoiceRecorderId,
      voiceSettingType: this.project.voiceSetting,
      voicerReadingSpeed: this.project.voicerReadingSpeed || 190,
      automatedSoundCorrection:
        this.project.automatedSoundCorrection === undefined
          ? true
          : this.project.automatedSoundCorrection,
    });
  }

  public isFileUploadListValid(): boolean {
    return (
      this.fileUploadContexts.filter(
        (files) => !files.isDeleted && files.isValidated
      ).length > 0
    );
  }

  public undoDelete(file: FileUploadContext) {
    this.recentlyDeletedFile = null;
    this.undo(file);
  }

  // Generate Vd file and process mixing.
  generateVdFileAndProcessMix() {
    const isWavFileUploaded = this.fileUploadContexts.filter(
      (e) => e.isEdited && e.file.type.split("/")[1] === "wav"
    ).length;
    this.projectReview.stereoFilePath =
      this.projectReview.stereoFilePath?.includes("https")
        ? this.projectReview.stereoFilePath
        : !!isWavFileUploaded
        ? `${this.projectReview.uniqueProjectNames[0]}/${this.projectReview.fileDetails[0].files.stereoFilePath}`
        : "";

    this.projectReview.replies = this.project?.replies;
    this.textToSpeechService
      .generateVdFileAndProcessMix(this.projectReview)
      .subscribe();
  }

  // Only auphonic mixing.
  startMix() {
    const isWavFileUploaded = this.fileUploadContexts.filter(
      (e) => e.isEdited && e.file.type.split("/")[1] === "wav"
    );
    const automatedMix = {
      stereoTrackFileUrl: this.project.stereoFilePath,
      vDTrackFileUrl: this.project.vdFilePath,
      projectName: this.formGroup.get("projectName").value,
      uniqueProjectName: this.project.uniqueProjectName,
      fromUI: true,
    } as CreateAndStartProduction;

    if (!automatedMix.stereoTrackFileUrl || isWavFileUploaded) {
      automatedMix.stereoTrackFileUrl = `${
        this.project.uniqueProjectName
      }/${encodeS3URI(this.projectReview.fileDetails[0].files.stereoFilePath)}`;
    }

    this.projectReview.stereoFilePath =
      this.projectReview.stereoFilePath?.includes("https")
        ? this.projectReview.stereoFilePath
        : !!isWavFileUploaded
        ? `${this.projectReview.uniqueProjectNames[0]}/${this.projectReview.fileDetails[0].files.stereoFilePath}`
        : "";

    this.projectReview.replies = this.project?.replies;

    this.textToSpeechService
      .generateVdFile(this.projectReview)
      .subscribe((vdFilePath) => {
        automatedMix.vDTrackFileUrl = vdFilePath;
        this.textToSpeechService.startMix(automatedMix).subscribe();
      });
  }

  public updateProject(restartMix = false) {
    this.spinner = true;
    this.buildProject();
    if (this.isProjectNameTooLong(this.projectReview as ProjectsDto)) {
      this.projectNameTooLong();
      return;
    }
    this.spinner = true;
    this.projectReview.userId = this.project.userId;
    this.projectReview.id = this.project.id;
    this.projectReview.projectStatus = this.project.status;
    this.projectReview.audioFilePath = this.project.audioFilePath;

    let retakesToPerform = 0;

    for (let index = 0; index < this.project.replies.length; index++) {
      if (this.project.replies[index].isLongAudio) {
        retakesToPerform++;
      }
    }
    this.projectService.update(this.projectReview).subscribe(
      () => {
        if (
          this.project.status === ProjectStatus.ReadyToDownload &&
          (this.projectReview.isAutomatedMix || restartMix) &&
          this.project.startTimeCode !== this.projectReview.startTimeCode
        ) {
          this.generateVdFileAndProcessMix();
        } else if (
          this.project.status === ProjectStatus.ReadyToDownload &&
          (this.projectReview.isAutomatedMix || restartMix) &&
          retakesToPerform === 0 &&
          (this.project.stereoFilePath ||
            this.projectReview.fileDetails[0].files.stereoFilePath)
        ) {
          this.startMix();
        }

        this.spinner = false;
        this.router.navigateByUrl("/projects");
      },
      () => {
        this.disableControl = false; // enable continue button if there is any error.
        this.spinner = false;
      }
    );
  }

  public save(projectsDto: ProjectsDto, goToProjectListing?: boolean) {
    if (this.isProjectNameTooLong(projectsDto)) {
      this.projectNameTooLong();
      return;
    }
    this.spinner = true;
    this.setProjectStatus(projectsDto);

    this.projectService.save(projectsDto).subscribe(
      (data) => {
        this.formGroup.patchValue({
          uniqueProjectName: data.data.uniqueProjectName,
        });
        if (
          this.formGroup.get("voiceSettingType").value === VoiceType.Synthetic
        ) {
          this.processXmlFile(data.isSuccess, projectsDto);
        } else if (this.isVoicerSelected && projectsDto?.replies?.length) {
          this.generateVdFileAndProcessMix();
        }

        if (goToProjectListing) {
          this.router.navigateByUrl("/projects");
        }

        this.spinner = false;
      },
      (err) => {
        alert(err.error.message);
        this.disableControl = false; // enable save button if there is any error.
        this.spinner = false;
      }
    );
  }

  public restartProject(projectDto: ProjectsDto) {
    if (this.isProjectNameTooLong(projectDto)) {
      this.projectNameTooLong();
      return;
    }
    this.spinner = true;
    projectDto.userId = this.project.userId;
    projectDto.id = this.project.id;
    this.setProjectStatus(projectDto);
    this.projectService.update(projectDto).subscribe(
      (data) => {
        if (
          this.formGroup.get("voiceSettingType").value === VoiceType.Synthetic
        ) {
          this.processXmlFile(data.isSuccess, projectDto);
        } else if (this.isVoicerSelected) {
          this.generateVdFileAndProcessMix();
        }
        this.router.navigateByUrl("/projects");
        this.spinner = false;
      },
      () => {
        this.disableControl = false; // enable save button if there is any error.
        this.spinner = false;
      }
    );
  }

  get isAllFilesAreXml(): boolean {
    return (
      !!this.fileUploadContexts?.length &&
      this.fileUploadContexts.every((upload) =>
        upload.file.name.includes(".xml")
      )
    );
  }

  get isAllFilesAreWav(): boolean {
    return (
      !!this.fileUploadContexts?.length &&
      this.fileUploadContexts.every((upload) =>
        upload.file.name.includes(".wav")
      )
    );
  }

  goToScriptWriting() {
    this.buildReview();
    this.spinner = true;
    this.buildProject();
    const onlyXmlFileUploaded =
      this.fileUploadContexts?.length === 1 && this.isXmlFileUploaded;

    if (this.project) {
      this.project = { ...this.project, ...this.projectReview };
      this.showProjectSection = false;
      this.spinner = false;
      return;
    }

    if (
      !onlyXmlFileUploaded &&
      (this.isAllFilesAreWav || this.isAllFilesAreXml)
    ) {
      this.save(this.projectReview, true);
    } else if (onlyXmlFileUploaded) {
      this.saveProject();
    } else if (!this.project && !this.isXmlFileUploaded) {
      this.saveProject();
    } else {
      this.showProjectSection = false;
      this.spinner = false;
    }
  }

  public saveProject(): void {
    this.spinner = true;
    this.projectService.save(this.projectReview).subscribe((data) => {
      this.formGroup.patchValue({
        uniqueProjectName: data.data.uniqueProjectName,
      });
      this.getProjectByUniqueProjectName();
    });
  }

  public getProjectByUniqueProjectName(): void {
    this.projectService
      .getByUniqueProjectName(this.projectReview.uniqueProjectNames[0])
      .subscribe((result) => {
        this.project = result;
        this.projectId = result.id;
        this.spinner = false;
        this.showProjectSection = false;
      });
  }

  create() {
    this.buildReview();
    this.spinner = true;
    this.buildProject();
    this.projectReview.startVd = true;
    this.projectReview.replies = this.project?.replies || [];
    !this.projectReview.isRestarting
      ? this.save(this.projectReview)
      : this.restartProject(this.projectReview);
  }

  public getStatus(assetId: string, fileName: string): string | null {
    const fileUploaded = this.fileUploadContexts.find(
      (file) => file.file.name === fileName && file.assetId === assetId
    );
    return fileUploaded ? fileUploaded.upload.status : null;
  }

  // Returns time code in sec.
  private calculateTimeCodeInSec(timeCode: string, frameRate: number) {
    if (timeCode?.includes(".", 8)) {
      return getTimeInSecondsFromMillis(timeCode);
    } else if (timeCode?.includes(":", 8)) {
      return getTimeInSecondsFromFrameRate(timeCode, frameRate);
    } else {
      return 0;
    }
  }

  // Parse the ttml xml file and get the framerate and first tcIn of the segment from xml.
  public async parseTtmlAndCalculateSilence(ttmlString: string) {
    this.nonDropFileWarningMessage = "";
    const xMLDocument = new DOMParser().parseFromString(ttmlString, "text/xml");
    const frameRate = !this.formGroup.get("frameRate").value
      ? Number(
          xMLDocument
            .getElementsByTagName("tt")[0]
            .getAttribute("ttp:frameRate")
        )
      : this.formGroup.get("frameRate").value;
    const startTcIn = xMLDocument
      .getElementsByTagName("p")[0]
      .getAttribute("begin");
    frameRate !== 0
      ? this.formGroup.patchValue({ frameRate: frameRate })
      : this.formGroup.patchValue({ frameRate: "" });
    const firstTcInInSec = this.calculateTimeCodeInSec(startTcIn, frameRate);
    const startTimeCodeInSec = this.calculateTimeCodeInSec(
      this.formGroup.get("startTimeCode").value,
      frameRate
    );
    const dropMode = xMLDocument
      .getElementsByTagName("tt")[0]
      .getAttribute("ttp:dropMode");
    if (
      dropMode &&
      dropMode.toLowerCase().includes("nondrop") &&
      environment.project.nonDropFrameRates.includes(frameRate)
    ) {
      this.nonDropFileWarningMessage = this.translateService.instant(
        "createProject.nodDropFileWarning"
      );
    }
    return [firstTcInInSec, startTimeCodeInSec];
  }

  // Calculate the silence between start time code and first tcIn while editing project.
  private async calculateSilenceOnEdit() {
    if (this.project.replies) {
      const frameRate = this.formGroup.get("frameRate").value;
      const firstTcInInSec = this.calculateTimeCodeInSec(
        this.project.replies![0]?.tcIn,
        frameRate
      );
      const startTimeCodeInSec = this.calculateTimeCodeInSec(
        this.formGroup.get("startTimeCode").value,
        frameRate
      );
      this.setSilenceWarningMessage(firstTcInInSec, startTimeCodeInSec);
    }
  }

  // Calculate the silence between start time code and first tcIn while crating project.
  private async calculateSilenceOnCreate() {
    let ttmlString: string;
    const reader = new FileReader();
    const ttmlAttached = this.fileUploadContexts.filter(
      (file) => file.file.name.split(".").reverse()[0] === "xml"
    );

    if (ttmlAttached.length) {
      reader.readAsText(ttmlAttached[0].file, "utf8");
      reader.onloadend = async () => {
        ttmlString = reader.result as string;
        if (!!ttmlString) {
          ttmlString = reader.result as string;
          const [firstTcInInSec, startTimeCodeInSec] =
            await this.parseTtmlAndCalculateSilence(ttmlString);
          this.setSilenceWarningMessage(
            Number(firstTcInInSec),
            Number(startTimeCodeInSec)
          );
        }
      };
    }
  }

  // on change of start time code check the silence.
  public async onStartTimeCodeChange() {
    this.silenceWarningMessage = "";
    this.validateStartTimeCode();

    if (this.projectId) {
      await this.calculateSilenceOnEdit();
    } else {
      await this.calculateSilenceOnCreate();
    }
  }

  setSilenceWarningMessage(firstTcInInSec: number, startTimeCodeInSec: number) {
    if (
      firstTcInInSec - startTimeCodeInSec >=
        environment.project.projectWarningSilence &&
      !this.project?.isGeneratedTtmlFile
    ) {
      this.silenceWarningMessage = this.translateService.instant(
        "createProject.silenceWarning"
      );
    } else if (
      startTimeCodeInSec > firstTcInInSec &&
      !this.project?.isGeneratedTtmlFile
    ) {
      this.silenceWarningMessage = this.translateService.instant(
        "createProject.startTimeCodeError"
      );
    }
  }

  public hideContinueButton() {
    const isXmlFileUploaded =
      this.fileUploadContexts.filter(
        (e) => e.isEdited && e.file.type.split("/")[1] === "xml"
      ).length > 0;
    return (
      isXmlFileUploaded ||
      !!this.project.errorMessage ||
      this.project.status === ProjectStatus.WaitForTTML
    );
  }

  public disableContinueAndRestart() {
    return (
      this.disableControl ||
      !!this.silenceWarningMessage ||
      !!this.nonDropFileWarningMessage ||
      !this.isAllFilesUploaded ||
      this.isNotEditable
    );
  }

  public getAllTemplates() {
    this.managementService.getAllTemplates().subscribe(
      (data) => {
        if (data) {
          const response = data
            .map((t) => {
              t.clientTemplateName = `${t.clientName}-${t.name}`;
              return t;
            })
            .sortBy("clientName");
          this.projectTemplate = this.backedProjectTemplate = response;
        } else {
          this.projectTemplate = this.backedProjectTemplate = [];
        }
      },
      () => {},
      () => {
        if (this.projectId) {
          this.selectedPresetTemplate = this.projectTemplate.filter(
            (template) => template.id === this.project.templateId
          )[0];
        }
      }
    );
  }

  initializeTemplate() {
    /* if input is something which does not give any result, onBlur should reassign the template,
    otherwise template list will be empty.
    -> in the template list it will be visible so there should be some delay.
    */
    setTimeout(() => {
      this.projectTemplate = this.backedProjectTemplate;
    }, 1000);
  }

  // Receive user input and send to search method**
  templateSearch(value: string) {
    this.projectTemplate = this.search(value);
  }

  // filter templates
  search(value: string) {
    const filterValue = value.toLowerCase().trim();
    if (filterValue === "") {
      return this.backedProjectTemplate;
    } else {
      return this.backedProjectTemplate.filter((t) =>
        t.clientTemplateName.toLowerCase().includes(filterValue)
      );
    }
  }

  usePresetTemplate(projectTemplate: ProjectTemplate) {
    this.onStartTimeCodeChange();

    this.selectedPresetTemplate = projectTemplate;
    this.formGroup.patchValue({
      language:
        this.formGroup.controls["language"].pristine ||
        (this.formGroup.controls["language"].dirty &&
          this.formGroup.controls["language"].untouched)
          ? projectTemplate.language
          : this.formGroup.get("language").value,
      region:
        this.formGroup.controls["region"].pristine ||
        (this.formGroup.controls["region"].dirty &&
          this.formGroup.controls["region"].untouched)
          ? projectTemplate.region
          : this.formGroup.get("region").value,
      voice:
        this.formGroup.controls["voice"].pristine ||
        (this.formGroup.controls["voice"].dirty &&
          this.formGroup.controls["voice"].untouched)
          ? projectTemplate.voice
          : this.formGroup.get("voice").value,
      startTimeCode:
        this.formGroup.controls["startTimeCode"].pristine ||
        (this.formGroup.controls["startTimeCode"].dirty &&
          this.formGroup.controls["startTimeCode"].untouched)
          ? projectTemplate.startTimeCode
          : this.formGroup.get("startTimeCode").value,
      speed:
        this.formGroup.controls["speed"].pristine ||
        (this.formGroup.controls["speed"].dirty &&
          this.formGroup.controls["speed"].untouched)
          ? projectTemplate.speedRate
          : this.formGroup.get("speed").value,
      automatedSpeedRate:
        this.formGroup.controls["automatedSpeedRate"].pristine ||
        (this.formGroup.controls["automatedSpeedRate"].dirty &&
          this.formGroup.controls["automatedSpeedRate"].untouched)
          ? projectTemplate.automatedSpeedRate
          : this.formGroup.get("automatedSpeedRate").value,
      isAutomatedSpeed:
        this.formGroup.controls["isAutomatedSpeed"].pristine ||
        (this.formGroup.controls["isAutomatedSpeed"].dirty &&
          this.formGroup.controls["isAutomatedSpeed"].untouched)
          ? projectTemplate.isAutomatedSpeedRate
          : this.formGroup.get("isAutomatedSpeed").value,
      isAutomatedMix:
        this.formGroup.controls["isAutomatedMix"].pristine ||
        (this.formGroup.controls["isAutomatedMix"].dirty &&
          this.formGroup.controls["isAutomatedMix"].untouched)
          ? projectTemplate.isAutomatedMix
          : this.formGroup.get("isAutomatedMix").value,
    });

    this.onLanguageSelectionChange(
      this.formGroup.get("language").value,
      true,
      projectTemplate.lexiconId
    );

    this.formGroup.patchValue({
      voice: projectTemplate.voice?.split("|")[1].trim() || "",
    });
  }

  automatedSpeedMessage() {
    let message = this.translateService.instant(
      "createProject.automatedSpeedMessage"
    );
    const maskedVoiceName =
      this.formGroup.get("voice").value && this.voices
        ? this.voices.find((voice) => {
            return voice.shortName === this.formGroup.get("voice").value;
          })
        : undefined;
    message = this.formGroup.get("voice").value
      ? this.translateService
          .instant("createProject.automatedSpeedMessage")
          .replace(
            "{voice}",
            maskedVoiceName ? maskedVoiceName?.maskedShortName : undefined
          )
      : message;
    message = message.replace("{speedRate}", this.formGroup.get("speed").value);
    message = message.replace(
      "{automatedSpeed}",
      this.formGroup.get("automatedSpeedRate").value
    );
    return message;
  }

  // When the user changes the voice or the lexicon, speed rate, automated variable speed or frame rate, user shouldn’t be able to click on “continue” which will not apply his changes but he can click on either Restart or back
  compareProjectData() {
    const currentProjectData: ProjectDataBackup = {
      voice: this.formGroup.get("voice").value,
      lexicon: this.formGroup.get("lexicon").value,
      speed: this.formGroup.get("speed").value,
      automatedSpeedRate: this.formGroup.get("automatedSpeedRate").value,
      frameRate: this.formGroup.get("frameRate").value,
      isAutomatedSpeed: this.formGroup.get("isAutomatedSpeed").value,
    };
    return (
      JSON.stringify(currentProjectData) !==
      JSON.stringify(this.projectDataBackup)
    );
  }

  isVoiceEmptyOrNull(): boolean {
    if (this.isVoicerSelected) {
      return false;
    }
    return (
      !this.voices ||
      this.formGroup.get("voice").value === "" ||
      this.isMatchingVoiceSelected()
    );
  }

  // to make sure that the correct voice from [lang-reg pair] is selected
  isMatchingVoiceSelected(): boolean {
    if (this.formGroup.get("voice").value !== "" && this.voices) {
      const voiceName = this.formGroup.get("voice").value;
      const voiceDetail = this.voices.find((voice) => {
        return voice.shortName === voiceName;
      });
      return voiceDetail && voiceDetail?.maskedShortName ? false : true;
    } else {
      return true;
    }
  }

  get disableSettingsNextBtn(): boolean {
    return (
      !this.retakesGenerationStoredInDb ||
      !this.formGroup.valid ||
      !!this.silenceWarningMessage ||
      !!this.nonDropFileWarningMessage ||
      this.isVoiceEmptyOrNull() ||
      this.spinner ||
      !this.uploadSuccessStatus ||
      (environment.project.nonEditableStatus.includes(
        this.project?.status as ProjectStatus
      ) &&
        !this.project?.errorMessage)
    );
  }

  get uploadSuccessStatus(): boolean {
    return this.unRemovedFileUploadContexts.length
      ? this.unRemovedFileUploadContexts.every((fileUploadContext) => {
          return fileUploadContext.upload.status == this.status.UploadSuccess;
        })
      : true;
  }

  get xmlFileUploadContexts() {
    return this.fileUploadContexts.filter(
      (fileUpload) =>
        fileUpload.file.name.includes(".xml") && !fileUpload.isDeleted
    );
  }

  get canDisplaySelectedAssetsFromDropbox(): boolean {
    return !!this.selectedAsset;
  }

  public removeSelectedVideoAsset(): void {
    this.selectedAsset = null;
    this.assetSelectionService.removeSelection();
  }

  public updateProjectFromScript(project: Project) {
    this.project = project;
    this.cdk.detectChanges();
  }
}
