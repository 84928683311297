import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignInComponent } from "./account/sign-in/sign-in.component";
import { SignUpComponent } from "./account/sign-up/sign-up.component";
import { ForgetPasswordComponent } from "./account/forget-password/forget-password.component";
import { ResetPasswordComponent } from "./account/reset-password/reset-password.component";
import { ForgetPasswordConfirmationComponent } from "./account/forget-password/forget-password-confirmation/forget-password-confirmation.component";
import { AssetComponent } from "./asset/asset.component";

const routes: Routes = [
  {
    path: "signUp",
    component: SignUpComponent,
  },
  {
    path: "signIn",
    component: SignInComponent,
  },
  {
    path: "account/forgetPassword",
    component: ForgetPasswordComponent,
  },
  {
    path: "account/expiredPassword",
    component: ForgetPasswordComponent,
    data: { expire: true },
  },
  {
    path: "account/forgetPassword/:email/confirmation",
    component: ForgetPasswordConfirmationComponent,
  },
  {
    path: "account/:token/resetPassword",
    component: ResetPasswordComponent,
  },
  {
    path: "projects",
    loadChildren: () =>
      import("./textToSpeech/textToSpeech.module").then(
        (m) => m.TextToSpeechModule
      ),
  },
  {
    path: "management",
    loadChildren: () =>
      import("./management/management.module").then((m) => m.ManagementModule),
  },
  {
    path: "lexicons",
    loadChildren: () =>
      import("./lexicon/lexicon.module").then((m) => m.LexiconModule),
  },
  {
    path: "assets",
    loadChildren: () =>
      import("./asset/asset.module").then((m) => m.AssetModule),
  },
  {
    path: "video-asset-selection",
    component: AssetComponent,
    data: {
      assetsSelection: true,
    },
  },
  {
    path: "",
    redirectTo: "/signIn",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/signIn",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
